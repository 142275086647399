import { NgModule } from "@angular/core";
import { PhoneMaskDirective } from "../core/directives/phone-mask.directive";

@NgModule({
    imports: [],
    providers: [],
    declarations: [
      PhoneMaskDirective
    ],
    exports: [
      PhoneMaskDirective
    ],
  })
  export class PhoneModule {}
