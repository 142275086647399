import { Routes } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { LayoutComponent } from "../layout/layout.component";
import { LoginComponent } from "./authentication/login/login.component";
import { RecoverComponent } from "./authentication/recover/recover.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { SignUpNewComponent } from "./authentication/sign-up-new/sign-up-new.component";
import { SignUpUpdatesComponent } from "./authentication/sign-up-updates/sign-up-updates.component";

export const routes: Routes = [
  { path: "", redirectTo: "portal", pathMatch: "full" },
  {
    path: "portal",
    component: LayoutComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "auth",
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      { path: "reset", component: ResetPasswordComponent },
      // { path: "sign-up", component: SignUpComponent },
      { path: "recover/:user", component: RecoverComponent },
      { path: "sign-up/updates", component: SignUpUpdatesComponent },
      { path: "sign-up/new", component: SignUpNewComponent },
    ],
  },
  {
    path: "error500",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  // Not found
  {
    path: "**",
    redirectTo: "portal",
  },
];
